




































































































































































































































































import { Component, Mixins, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, CountDown, Image, Icon, Dialog, Toast } from "vant";
import PerHead from "@/views/TicketOrder/PerOrderDetails/components/PerHead.vue";
import PerContact from "@/views/Mall/Payment/Contact.vue";
import DistributionWay from "@/views/Mall/Payment/DistributionWay.vue";
import PayLine from "@/views/Mall/Payment/Line.vue";
import OrderDetails from "@/views/Mall/Payment/OrderDetails.vue";
import OrderInfo from "@/views/Mall/Payment/OrderInfo.vue";
import PerTelephone from "@/views/Mall/Payment/Telephone.vue";
import SeatInfo from "@/views/TicketOrder/PerOrderDetails/components/SeatInfo.vue";
import PayBtn from "@/views/Mall/Payment/PayBtn.vue";
import InvoiceInfo from "@/views/Mall/Payment/InvoiceInfo.vue";
import PerCode from "@/views/TicketOrder/PerOrderDetails/components/PerCode.vue";
import PerSteps from "@/views/TicketOrder/PerOrderDetails/components/PerSteps.vue";
import GoodsInfo from "@/views/Mall/Payment/GoodsInfo.vue";
import CollectTickets from "@/views/TicketOrder/PerOrderDetails/components/CollectTickets.vue";
import { Route, NavigationGuardNext } from "vue-router";
import EventBus from "@/utils/eventBus";
import { monitorEvent } from "@/utils/youMengMonitor";
import PerCollect from "@/views/TicketOrder/PerOrderDetails/components/PerCollect.vue";
import Survery from "./components/Survery.vue";

@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [CountDown.name]: CountDown,
    [Image.name]: Image,
    [Icon.name]: Icon,
    PerContact,
    PerHead,
    DistributionWay,
    PayLine,
    OrderDetails,
    OrderInfo,
    PerTelephone,
    SeatInfo,
    PayBtn,
    InvoiceInfo,
    PerCode,
    PerSteps,
    GoodsInfo,
    CollectTickets,
    PerCollect,
    Survery,
  },
})
export default class PerOrder extends Mixins(Mixin) {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      (vm as PerOrder).popFullPath = from.fullPath;
      (vm as PerOrder).fromRouteName = from.name || "";
    });
  }
  public popFullPath = "";
  emitRefreshOrderList = false;
  orderInfo: order.EsOrderDetailTicketInfo = {}; //订单信息
  scrollTop = 0;
  orderId = ""; //订单id
  peopleInfo = {
    //用户信息
    name: "",
    num: "",
  };
  productSourceEnum = ""; //判断来源
  isIntervalTime = false; //是否展区间还是时间
  public thirdPart = false; //是否来自第三方
  fromRouteName = "";
  fraudPreventionReminder = ""; //防诈骗提醒
  showReminder = true;
  mounted(): void {
    // 非首次进入页面时需要在 mounted 中刷新页面
    if (
      this.ExternalAccessModule.isExternalAccessReady &&
      this.AppSource == "harmonyOS"
    ) {
      this.init();
    } else {
      this.init();
    }
  }
  init(): void {
    this.wxpay();
    window.addEventListener("scroll", this.handleScroll);
    this.orderId = this.$route.params.orderId || "";
    this.productSourceEnum = this.$route.params.productSourceEnum || "";
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.upDateInfo();
    this.getFraudPreventionReminder();
  }
  getFraudPreventionReminder(): void {
    this.$api.cmsApi.commonInfo.policy(
      "PAGE_FRAUD_PREVENTION_REMINDER",
      ({ data }) => {
        this.fraudPreventionReminder = data;
      }
    );
  }
  closeReminder(): void {
    this.showReminder = false;
  }
  beforeDestroy(): void {
    if (this.emitRefreshOrderList) {
      EventBus.$emit("refreshActiveOrderList");
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  //判断哪一方的来源
  upDateInfo(): void {
    this.orderInfo = {};
    if (this.productSourceEnum === "POLY") {
      this.getDateInfo();
    } else if (this.productSourceEnum === "DM") {
      this.getDMInfo();
    } else if (this.productSourceEnum === "MY") {
      this.getMYInfo();
    } else if (this.productSourceEnum === "MH") {
      this.getMHInfo();
    }
  }

  handleScroll(): void {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      if (this.$route.query.status === "TRADE_SUCCESS") {
        this.emitRefreshOrderList = true;
        let url = `/personal-data/order?status=ALL`;
        this.$router.replace({
          path: url,
        });
      } else {
        if (!this.$route.query.status) {
          this.emitRefreshOrderList = true;
        }
        let url = this.$route.query.status
          ? `/personal-data/order?status=${this.$route.query.status}`
          : `/personal-data/order?status=ALL`;
        //
        this.$router.replace({
          path: url,
        });
      }
    }
  }

  //去详情
  goShowDetail(item: order.EsOrderDetailTicketInfo): void {
    this.goToProjectDetail(item.productId);
  }
  /*
   *平台项目详情
   */
  getDateInfo(): void {
    this.$api.orderApi.ticketOrder.getOrderDetailTicket(
      this.orderId,
      ({ data }) => {
        Toast.clear();
        // console.log(6666, data);
        // 20230523 add PWPTNEW-5858 【V3.15.0】平台H5：预售项目订单-座位信息为空串时UI兼容
        data.seatList = data.seatList?.map((item) => {
          return {
            seatName: item.seatName || "随机座",
            price: item.price,
          };
        });
        this.orderInfo = data;
        this.peopleInfo.name = data.contactsName!;
        this.peopleInfo.num = data.contactsPhone!;
        this.thirdPart = false; //不来自第三方 （冀宏宇add）
      },
      (error) => {
        Toast.clear();
        error && this.$toast(error);
      }
    );
  }
  /*
   *麻花项目详情
   */
  getMHInfo(): void {
    this.$api.orderApi.ticketOrder.getOrderMhDetailTicket(
      this.orderId,
      ({ data }) => {
        Toast.clear();
        let orderInfo: order.EsOrderDetailTicketInfo = {};
        orderInfo.orderStatus = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatus;
        orderInfo.orderStatusName = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatusName;
        this.peopleInfo.name = data.contactsName!;
        this.peopleInfo.num = data.contactsMobile!;
        orderInfo.projectImg = data.projectImg;
        orderInfo.policyDiscAmt = data.discAmt;
        orderInfo.productId = data.projectId;
        orderInfo.placeName = data.placeName;
        orderInfo.ticketFolderId = data.ticketFolderId;
        orderInfo.orderExpireTime = data.orderExpireTime;
        orderInfo.deliveryWay = "SCENE";
        orderInfo.showTime = data.showTime;
        orderInfo.orderTotalAmt = data.orderTotalAmt;
        orderInfo.num = data.ticketNum;
        orderInfo.productName = data.projectName;
        orderInfo.noticeDtos = data.showNotices || [];
        orderInfo.actuallyPaidAmt = data.actuallyPaidAmt;
        orderInfo.cityName = data.cityName;
        if (data.seatInfoList) {
          orderInfo.seatList = data.seatInfoList.map((item) => {
            return {
              seatName: item.seatName || "随机座",
              price: item.seatPrice,
            };
          });
        }
        orderInfo.payInfoList = [
          {
            key: "现金",
            price: String(data.orderPrice) as string | undefined,
            unit: "元",
          },
        ];
        orderInfo.salesOrderCode = data.orderCode;
        orderInfo.orderCreateTime = data.createTime;
        orderInfo.payTime = data.payTime;
        orderInfo.payWayCode = data.payWayCode;
        orderInfo.ticketCode = data.ticketCode;
        orderInfo.contactNumber = data.theaterTel;

        this.thirdPart = true; //来自第三方 （冀宏宇add）
        this.orderInfo = orderInfo;
      },
      (error) => {
        Toast.clear();
        error && this.$toast(error);
      }
    );
  }
  /*
   *大麦项目详情
   */
  getDMInfo(): void {
    this.$api.orderApi.ticketOrder.getOrderDMDetailTicket(
      this.orderId,
      ({ data }) => {
        Toast.clear();
        this.peopleInfo.name = data.contactsName || "";
        this.peopleInfo.num = data.contactsPhone || "";
        let orderInfo: order.EsOrderDetailTicketInfo = {};
        orderInfo.orderStatus = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatus;
        orderInfo.orderStatusName = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatusName;
        orderInfo.consigneeName = data.consigneeName;
        orderInfo.consigneePhone = data.consigneePhone;
        orderInfo.actuallyPaidAmt = data.actuallyPaidAmt;
        orderInfo.orderCreateTime = data.createTime;
        orderInfo.payTime = data.payTime;
        orderInfo.payWayCode = data.payWayName;
        orderInfo.orderExpireTime = data.orderExpireTime;
        orderInfo.receivingAddress = data.receivingAddress;
        orderInfo.orderFreightAmt = data.orderFreightAmt;
        orderInfo.orderTotalAmt = data.orderTotalAmt;
        orderInfo.ticketFolderId = data.ticketFolderId;
        orderInfo.ticketCode = data.ticketCode;
        orderInfo.contactNumber = data.theaterTel;
        orderInfo.deliveryWay = data.deliveryWay;
        if (data.expressInfo) {
          orderInfo.expressName = data.expressInfo.deliveryCompanyName;
          orderInfo.expressNumber = data.expressInfo.waybillNo;
        }
        orderInfo.ticketCollectionAddr = data.exchangePoints?.map(
          (el: any) => el.pointAddr
        );
        orderInfo.salesOrderCode = data.salesOrderCode;
        if (data.productInfo && data.productInfo.projectInfo) {
          let item = data.productInfo.projectInfo;
          orderInfo.projectImg = item.img;
          orderInfo.placeName = item.showPlaceName;
          orderInfo.productName = item.productName;
          orderInfo.productId = String(item.productId || "");
        }
        if (data.productInfo && data.productInfo.showInfo) {
          let cityItme = data.productInfo.showInfo;
          orderInfo.cityName = cityItme.cityName;
          orderInfo.showTime = cityItme.happenTime;
          orderInfo.noticeDtos = cityItme.showNotices || [];
        }
        if (data.seatList) {
          orderInfo.num = data.seatList.length || 0;
          orderInfo.seatList = data.seatList.map((value) => {
            return {
              price: value.price,
              seatName: value.seatName || "随机座",
            };
          });
        }
        orderInfo.payInfoList = [
          {
            key: "现金",
            price: String(data.orderTotalAmt || 0),
            unit: "元",
          },
        ];
        this.orderInfo = orderInfo;
      },
      (error) => {
        Toast.clear();
        error && this.$toast(error);
      }
    );
  }
  /*
   *猫眼项目详情
   */
  getMYInfo(): void {
    this.$api.orderApi.ticketOrder.getOrderMyDetailTicket(
      this.orderId,
      ({ data }) => {
        Toast.clear();
        let orderInfo: any = {};
        orderInfo.orderStatus = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatus;
        orderInfo.orderStatusName = this.dealStatus(
          String(data.polyOrderStatus)
        ).orderStatusName;
        orderInfo.orderExpireTime = data.orderExpireTime;
        this.peopleInfo.name = data.contactsName!;
        this.peopleInfo.num = data.contactsPhone!;
        if (data.openOrder) {
          let val = data.openOrder;
          (orderInfo.productId as string) = String(val.performanceId);
          if (val.delivery) {
            orderInfo.localeContactPersons = val.delivery.localeContactPersons;
            orderInfo.deliveryWay = this.dealWayStatus(val.delivery.fetchType);
            orderInfo.cityName = val.delivery.cityName;
            orderInfo.expressName = val.delivery.expressCompany;
            orderInfo.expressNumber = val.delivery.expressNo;
            orderInfo.consigneeName = val.delivery.recipientName;
            orderInfo.consigneePhone = val.delivery.recipientMobile;
            orderInfo.receivingAddress = val.delivery.detailedAddress;
            orderInfo.tips = val.delivery.tips;
          }
          orderInfo.num = val.tickets ? val.tickets.length : 0;
          orderInfo.orderTotalAmt = Number(val.price?.totalTicketPrice);
          orderInfo.seatList = val.tickets?.map((value) => {
            return {
              price: Number(value.ticketPrice),
              seatName: value.seatName || "随机座",
            };
          });
          orderInfo.payInfoList = [
            {
              key: "现金",
              price: val.price?.totalTicketPrice,
              unit: "元",
            },
          ];
          orderInfo.orderFreightAmt = Number(val.price?.postage);
          orderInfo.actuallyPaidAmt = Number(val.price?.totalPrice);
          orderInfo.salesOrderCode = val.partnerOrderId;
          let arr: Array<string> = [];
          let fetchAddress = val.delivery?.fetchAddress;
          let str = fetchAddress ? String(fetchAddress) : "";
          arr.push(str);
          orderInfo.ticketCollectionAddr = fetchAddress ? arr : [];

          orderInfo.ticketCode = val.delivery?.fetchQrCode;
          // 0、1、2-未配座：取票码模糊显示 3-已配座：正常显示取票码区域 （仅限于猫眼项目)
          orderInfo.seatMatchStatus = val.seatMatchStatus;
        }
        orderInfo.noticeDtos = data.productInfo?.showInfo?.showNotices || [];
        orderInfo.payTime = data.payTime;
        orderInfo.payWayCode = data.payWayName;
        if (data.productInfo && data.productInfo.projectInfo) {
          let item = data.productInfo.projectInfo;
          orderInfo.projectImg = item.img;
          orderInfo.placeName = item.showPlaceName;
          orderInfo.productName = item.productName;
        }
        orderInfo.showTime = data.productInfo?.showInfo?.happenTime;
        orderInfo.orderCreateTime = data.createTime;
        orderInfo.ticketFolderId = data.ticketFolderId;
        this.thirdPart = true; //来自第三方
        orderInfo.contactNumber = data.theaterTel || "";
        this.orderInfo = orderInfo;
      },
      (error) => {
        Toast.clear();
        error && this.$toast(error);
      }
    );
  }

  // 禁用项目详情页面分享
  wxpay(): void {
    let a = window as any;
    a.WeixinJSBridge && a.WeixinJSBridge.call("hideOptionMenu");
  }
  /*
   * 处理订单状态
   */
  dealStatus(status: string): {
    orderStatus: string;
    orderStatusName: string;
  } {
    let data = {
      orderStatus: "",
      orderStatusName: "",
    };
    if (status === "01") {
      // 交易完成
      data.orderStatus = "ORDER_COMPLATE";
      data.orderStatusName = "交易完成";
    } else if (status === "02") {
      // 待付款
      data.orderStatus = "PEND_PAYMENT";
      data.orderStatusName = "待付款";
    } else if (status === "03") {
      //待发货
      data.orderStatus = "WAIT_SEND";
      data.orderStatusName = "待发货";
    } else if (status === "04") {
      //待出票
      data.orderStatus = "TICKET_PAYABLE";
      data.orderStatusName = "待出票";
    } else if (status === "05") {
      //待自提
      data.orderStatus = "SHOP";
      data.orderStatusName = "待自提";
    } else if (status === "06") {
      //待收货
      data.orderStatus = "WAIT_GET_TICKET";
      data.orderStatusName = "待收货";
    } else if (status === "07") {
      //交易取消
      data.orderStatus = "CANCLE";
      data.orderStatusName = "交易取消";
    } else if (status === "08") {
      //8：已取票
      data.orderStatus = "RECEIVED";
      data.orderStatusName = "已取票";
    }

    return data;
  }

  /*
   * 处理配送状态
   */
  dealWayStatus(type?: number): string {
    let wayStatus = "";
    if (type === 1) {
      //上门自取
      wayStatus = "SHOP";
    } else if (type === 2) {
      //快递配送
      wayStatus = "EXPRESS";
    } else if (type === 4) {
      //自助取票
      wayStatus = "SCENE";
    } else if (type === 5) {
      //电子票
      wayStatus = "ETICKET";
    } else if (type === 7) {
      //临场派票
      wayStatus = "SHOW_UP";
    } else if (type === 8) {
      //身份证入场
      wayStatus = "CARD";
    }
    return wayStatus;
  }
  /*
   *去票夹页
   */
  goWallet(ticketFolderId: string): void {
    monitorEvent("OrderDetail_ClickTicketWallet", "订单详情点击去票夹"); // 埋点：订单详情，订单详情点击去票夹
    this.$router.push(
      `/wallet/details/${ticketFolderId}?formOrder=1&orderId=${this.orderId}`
    );
  }
  /*
   *取消订单
   */
  payCancel(): void {
    Dialog.confirm({
      message: "您确定取消此订单吗？",
    })
      .then(() => {
        monitorEvent("OrderDetail_ClickCancel", "点击取消订单"); // 埋点：订单详情，点击取消订单
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
        });
        this.$api.orderApi.ticketOrder.cancelOrder(
          this.orderId,
          (data) => {
            if (data.data === true) {
              this.productIdModule.resetProductId_PERSIST();
              this.$toast("取消成功");
              this.emitRefreshOrderList = true;
              if (this.AppSource == "harmonyOS") {
                this.jsBridge("onCancelOrderSuccess", "");
              } else {
                this.$router.push({
                  path: `/personal-data/order`,
                  query: { status: this.$route.query.status || "ALL" },
                });
              }
            }
          },
          (error) => {
            error && this.$toast(error);
          }
        );
      })
      .catch(() => {
        // on cancel
      });
  }

  /*
   * 立即付款
   */
  payConfirm(): void {
    if (this.orderInfo.orderStatus === "PEND_PAYMENT") {
      monitorEvent("OrderDetail_ClickOrder", "点击立即付款"); // 埋点：订单详情，点击立即付款
      // 待付款
      if (this.AppSource == "harmonyOS") {
        let params = {
          getDeliveryWay: this.orderInfo.deliveryWay,
          payOderId: this.orderId,
          payMoney: this.orderInfo.actuallyPaidAmt?.toString(),
          lastTime: this.orderInfo.orderExpireTime,
          productId: this.orderInfo.productId,
          thirdOrderType: this.productSourceEnum,
        };
        this.jsBridge("onPayOrderImmediately", params);
      } else {
        if (this.$route.query.templateType == "BEST_PAY") {
          // 跳转到翼支付页面
          let bestpayParam: payment.PaymentOrderDTO = {
            channelEnum: "PLAT_H5",
            orderType: "SHOW",
            payMethodEnum: "BEST_PAY",
            orderId: this.orderId,
            distributionChannelId: this.$route.query
              .distributionChannelId as string,
            distributionSeriesId: this.$route.query
              .distributionSeriesId as string,
            sceneTypeEnum:
              Vue.prototype.$AppSource === "ios" ? "IOS_WAP" : "ANDROID_WAP",
            cancelOlderOrder: false,
          };
          this.$api.paymentApi.bestPayPaymentOrder.bestPayPaymentOrder(
            bestpayParam,
            ({ data }) => {
              // 跳转到翼支付页面
              this.$router.replace({
                path: "/other/pay",
                query: {
                  data: JSON.stringify(data),
                  type: "BEST_PAY",
                  orderId: this.orderId,
                  orderType: "SHOW",
                  productSourceEnum: this.productSourceEnum,
                  productId: this.orderInfo.projectId,
                },
              });
            }
          );
        } else {
          this.$router.push({
            path: "/payconfirm",
            query: {
              orderId: this.orderId,
              orderType: "SHOW",
            },
          });
        }
      }
    } else if (this.orderInfo.orderStatus === "WAIT_GET_TICKET") {
      // 确认收货
      Dialog.confirm({
        confirmButtonText: "确定",
        message: `<div>
      <span>
        确认收到货了吗？
      </span>
        </div>
      `,
      })
        .then(() => {
          monitorEvent("OrderDetail_ClickConfirm", "点击确认收货"); // 埋点：订单详情，点击确认收货
          this.$api.orderApi.ticketOrder.orderConfirmReceipt(
            this.orderId,
            ({ data }) => {
              this.getDateInfo();
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    }
  }
  comment() {
    this.$router.push(
      `/write-comment/${this.orderInfo.productId}?orderId=${this.orderInfo.orderId}&productName=${this.orderInfo.productName}`
    );
  }
  /*
   *倒计时结束
   */
  finish(): void {
    this.upDateInfo();
    // this.$router.go(0);
    this.emitRefreshOrderList = true;
  }
}
