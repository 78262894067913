var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mall-details"},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{style:({ background: _vm.scrollTop > 50 ? '#fff' : ' rgba(0, 0, 0, 0)' }),attrs:{"fixed":"","border":false},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.6rem","height":"0.6rem","src":require('@/assets/images/Mall/d1.png')},on:{"click":function($event){$event.preventDefault();return _vm.onClickLeft.apply(null, arguments)}}})]},proxy:true}],null,false,2129942672)}):_vm._e(),_c('van-row',{staticClass:"my-swipe"},[_c('van-swipe',{staticClass:"d-swiper",on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator"},[_vm._v(" "+_vm._s(_vm.current + 1)+"/"+_vm._s(_vm.mallInfo.commoditiesPicture ? _vm.mallInfo.commoditiesPicture.length : 1)+" ")])]},proxy:true}])},[(_vm.mallInfo.commoditiesPicture)?_vm._l((_vm.mallInfo.commoditiesPicture),function(item,index){return _c('van-swipe-item',{key:index},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"100%","height":"100%","src":item},on:{"click":function($event){$event.preventDefault();return _vm.goImgDetails.apply(null, arguments)}}})],1)}):_c('van-swipe-item',[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"100%","height":"100%","src":require('@/assets/images/placeholder/banner.png')}})],1)],2)],1),_c('van-row',{staticClass:"m-main",attrs:{"id":"m-main"}},[_c('van-row',{staticClass:"m-one",attrs:{"type":"flex","justify":"space-between","align":"center"}},[(_vm.mallInfo.lowestMemberPrices)?_c('div',{staticClass:"o-left"},[(
            _vm.mallInfo.lowestMemberPrices[0].amount === 0 &&
            _vm.mallInfo.lowestMemberPrices[0].integral === 0 &&
            _vm.mallInfo.lowestMemberPrices[0].printing === 0
          )?[_vm._v(" "+_vm._s(_vm.mallInfo.lowestMemberPrices[0].amount)),_c('span',{staticClass:"unit"},[_vm._v("元")])]:_vm._e(),(
            _vm.mallInfo.lowestMemberPrices[0].amount &&
            _vm.mallInfo.lowestMemberPrices[0].amount > 0
          )?_c('span',[_vm._v(" "+_vm._s(_vm.mallInfo.lowestMemberPrices[0].amount.toFixed(2).split(".")[0])),_c('span',{staticClass:"unit"},[_vm._v("."+_vm._s(_vm.mallInfo.lowestMemberPrices[0].amount.toFixed(2).split(".")[1])+"元")])]):_vm._e(),(
            Number(_vm.mallInfo.lowestMemberPrices[0].amount) > 0 &&
            Number(_vm.mallInfo.lowestMemberPrices[0].integral) > 0
          )?_c('span',[_vm._v(" + ")]):_vm._e(),(Number(_vm.mallInfo.lowestMemberPrices[0].integral) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.mallInfo.lowestMemberPrices[0].integral)),_c('span',{staticClass:"unit"},[_vm._v("积分")])]):_vm._e(),(
            (Number(_vm.mallInfo.lowestMemberPrices[0].amount) ||
              Number(_vm.mallInfo.lowestMemberPrices[0].integral)) > 0 &&
            Number(_vm.mallInfo.lowestMemberPrices[0].printing) > 0
          )?_c('span',[_vm._v(" + ")]):_vm._e(),(Number(_vm.mallInfo.lowestMemberPrices[0].printing) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.mallInfo.lowestMemberPrices[0].printing)),_c('span',{staticClass:"unit"},[_vm._v("印花")])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"o-right"},[_vm._v(_vm._s(_vm.mallInfo.commodityLabel))])]),_c('van-row',{staticClass:"m-two"},[_vm._v(" "+_vm._s(_vm.mallInfo.commodityTitle))]),_c('van-row',{staticClass:"m-three"},[_vm._v(_vm._s(_vm.mallInfo.commoditySubTitle))]),_c('van-row',{staticClass:"m-four"},[_c('span',{staticClass:"f-title"},[_vm._v("兑换时间")]),_c('span',[_vm._v(_vm._s(_vm.timeFilter(false, _vm.mallInfo.exchangeStartTime))+" - "+_vm._s(_vm.timeFilter(false, _vm.mallInfo.exchangeEndTime)))])]),(
        _vm.mallInfo.commoditySpecs &&
        _vm.mallInfo.commoditySpecs.length > 0 &&
        _vm.mallInfo.commoditySpecs[0].commoditySpecsDetails &&
        _vm.mallInfo.commoditySpecs[0].commoditySpecsDetails.length > 0
      )?[(
          _vm.mallInfo.commoditySpecs.length == 1 &&
          _vm.mallInfo.commoditySpecs[0].commoditySpecsDetails.length === 1
        )?[_c('van-row',{staticClass:"s-number",attrs:{"type":"flex","justify":"space-between","align":"center"}},[_c('span',{staticClass:"s-n-title"},[_vm._v("数量")]),_c('van-stepper',{attrs:{"min":"1","max":_vm.pur,"integer":true,"input-width":"40px","button-size":"24px"},model:{value:(_vm.stepperNum),callback:function ($$v) {_vm.stepperNum=$$v},expression:"stepperNum"}})],1)]:_c('van-row',{staticClass:"m-size"},[_c('van-row',{staticStyle:{"padding-bottom":"0.2rem"},attrs:{"type":"flex","justify":"space-between","align":"center"},on:{"click":function($event){$event.preventDefault();return _vm.showSpecification.apply(null, arguments)}}},[_c('van-row',{staticClass:"s-title",attrs:{"type":"flex","justify":"start","align":"center"}},[_c('span',{staticClass:"s-t-left"},[_vm._v("规格")]),(_vm.mallInfo.commoditySpecsTypes)?_vm._l((_vm.mallInfo.commoditySpecsTypes),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item.specsTypeName)),(index != _vm.mallInfo.commoditySpecsTypes.length - 1)?_c('span',[_vm._v("/")]):_vm._e()])}):_vm._e()],2),_c('van-icon',{attrs:{"color":"#ddd","size":"14","name":"arrow"}})],1)],1)]:_vm._e(),_c('van-tabs',{staticClass:"tab-notic",model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"详情","name":"1"}},[_c('div',{staticClass:"t-n-content",domProps:{"innerHTML":_vm._s(_vm.dealPicture(_vm.mallInfo.detailDesc || ''))}})]),_c('van-tab',{attrs:{"title":"须知","name":"2"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.mallInfo.exchangeNotice)}})])],1)],2),_c('van-row',{staticClass:"footer"},[_c('div',{staticClass:"btn",style:({
        'background-color': _vm.mallInfo.status == '01' ? '#FFCD0F' : '#DDDDDD',
        color: _vm.mallInfo.status == '01' ? '#111' : '#bbb',
      }),on:{"click":_vm.goOrder}},[_vm._v(" "+_vm._s(_vm.btnName(_vm.mallInfo.status))+" ")]),(_vm.mallInfo.status == '03')?_c('div',{staticClass:"btn-hint bg"},[_vm._v(" "+_vm._s(_vm.timeFilter(true, _vm.mallInfo.exchangeStartTime))+" 开票 ")]):_vm._e()]),(_vm.dataInfo.show)?_c('CommoditySeen',{attrs:{"data-info":_vm.dataInfo,"info":_vm.mallInfo,"default-index-pic":_vm.mallInfo.commoditiesPicture ? _vm.mallInfo.commoditiesPicture[0] : '',"low-price":_vm.mallInfo.lowestMemberPrices,"status":_vm.mallInfo.status}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }