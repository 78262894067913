































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core//mixins/mixin";
import { NavBar, Row, Image, Field } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Field.name]: Field,
    ItemTitle,
  },
})
export default class Contact extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public peopleInfo!: [];

  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  public isAdvOrder!: boolean;

  // get telNumber(): string {
  //   return this.peopleInfo.num.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") || "";
  // }
}
