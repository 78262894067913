var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-pay"},[_c('van-nav-bar',{class:{ head: _vm.scrollTop < 50 },attrs:{"title":_vm.orderInfo.isSellOut === '2' &&
      _vm.orderInfo.orderStatusName !== '待付款' &&
      _vm.orderInfo.orderStatusName !== '交易关闭'
        ? '配票中'
        : _vm.orderInfo.orderStatusName,"left-arrow":"","border":false,"fixed":"","placeholder":true},on:{"click-left":_vm.onClickLeft}}),(_vm.orderInfo.orderStatus === 'PEND_PAYMENT')?_c('van-row',{staticClass:"p-time",attrs:{"type":"flex","align":"center","justify":"center"}},[_c('van-row',{attrs:{"type":"flex","align":"center"}},[_c('span',{staticClass:"p-t-title"},[_vm._v("支付倒计时：")]),_c('van-count-down',{staticStyle:{"font-size":"0.64rem","font-weight":"Medium"},attrs:{"time":Number(_vm.orderInfo.orderExpireTime),"format":"mm : ss "},on:{"finish":_vm.finish}})],1)],1):_vm._e(),(
      _vm.orderInfo.orderStatus === 'ORDER_COMPLATE' ||
      _vm.orderInfo.orderStatus === 'WAIT_SEND' ||
      _vm.orderInfo.orderStatus === 'WAIT_PICK_BYSELF' ||
      _vm.orderInfo.orderStatus === 'WAIT_GET_TICKET'
    )?_c('van-row',{staticClass:"m-h-bottom"},[_c('div',{staticClass:"m-wallet",on:{"click":function($event){$event.preventDefault();return _vm.goWallet(_vm.orderInfo.ticketFolderId || '')}}},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.36rem","height":"0.36rem","src":require('@/assets/images/PerOrder/p2.png')}}),_c('span',[_vm._v("查看票夹")]),_c('van-icon',{attrs:{"name":"arrow","size":".3rem","color":"#333"}})],1),_c('van-row',{staticClass:"m-b-hint"},[_vm._v("请前往票夹查看入场方式及演出信息")])],1):_vm._e(),(
      _vm.showReminder &&
      _vm.fraudPreventionReminder &&
      _vm.fraudPreventionReminder.length
    )?_c('van-row',{staticClass:"reminder"},[_c('div',{staticClass:"reminderTip",domProps:{"innerHTML":_vm._s(_vm.fraudPreventionReminder)}}),_c('van-icon',{attrs:{"name":"clear","size":".36rem","color":"#666"},on:{"click":_vm.closeReminder}})],1):_vm._e(),_c('PerHead',{attrs:{"is-order":true,"city-name":_vm.orderInfo.cityName,"head-img":_vm.orderInfo.projectImg,"place-name":_vm.orderInfo.placeName,"show-time-str":_vm.orderInfo.showTimeStr,"show-price":_vm.orderInfo.orderTotalAmt,"show-num":_vm.orderInfo.num,"show-name":_vm.orderInfo.productName,"notice-dtos":_vm.orderInfo.noticeDtos,"product-source-enum":_vm.productSourceEnum},on:{"goShowDetail":function($event){return _vm.goShowDetail(_vm.orderInfo)}}}),_c('distribution-way',{attrs:{"delivery-way":_vm.orderInfo.deliveryWay,"express-name":_vm.orderInfo.expressName,"express-number":_vm.orderInfo.expressNumber,"product-source-enum":_vm.productSourceEnum,"delivery-way-explain":_vm.orderInfo.tips}}),(
      (_vm.orderInfo.deliveryWay === 'SCENE' &&
        (_vm.orderInfo.orderStatus === 'WAIT_PICK_BYSELF' ||
          _vm.orderInfo.orderStatus === 'ORDER_COMPLATE')) ||
      (_vm.productSourceEnum === 'MY' && _vm.orderInfo.seatMatchStatus)
    )?[(
        _vm.orderInfo.ticketCode &&
        ((_vm.productSourceEnum === 'MY' && _vm.orderInfo.seatMatchStatus === 3) ||
          (_vm.productSourceEnum === 'POLY' && _vm.orderInfo.isSellOut !== '2'))
      )?_c('per-code',{attrs:{"product-source-enum":_vm.productSourceEnum,"order-status":_vm.orderInfo.orderStatus,"ticket-code":_vm.orderInfo.ticketCode}}):(_vm.orderInfo.isSellOut === '2')?_c('div',{staticClass:"preSalebox"},[_c('p',{staticClass:"preSaleTips"},[_vm._v(" 预售阶段暂不展示二维码，"),_c('br'),_vm._v("演出开售后支持自助取票和快递取票 ")]),_c('p'),_c('van-image',{staticClass:"preSale",attrs:{"fit":_vm.imageFit,"width":"3rem","height":"3rem","src":require("@/assets/images/Tickets/preSale.png")}})],1):(
        _vm.productSourceEnum === 'MY' &&
        _vm.orderInfo.seatMatchStatus !== 3 &&
        _vm.orderInfo.deliveryWay !== 'ETICKET' &&
        _vm.orderInfo.deliveryWay !== 'EXPRESS'
      )?_c('div',{staticClass:"preSalebox"},[_c('p',{staticClass:"preSaleTips"},[_vm._v("开演前公开")]),_c('p'),_c('van-image',{staticClass:"preSale",attrs:{"fit":_vm.imageFit,"width":"3rem","height":"3rem","src":require("@/assets/images/Tickets/preSale.png")}})],1):_vm._e(),(
        _vm.orderInfo.ticketCollectionAddr &&
        _vm.orderInfo.ticketCollectionAddr.length > 0
      )?_c('collect-tickets',{attrs:{"ticket-collection-addr":_vm.orderInfo.ticketCollectionAddr}}):_vm._e(),(
        _vm.orderInfo.deliveryWay === 'SHOW_UP' && _vm.orderInfo.localeContactPersons
      )?_c('per-collect',{attrs:{"people-info":_vm.orderInfo.localeContactPersons}}):_vm._e()]:_vm._e(),(_vm.orderInfo.deliveryWay === 'EXPRESS')?_c('goods-info',{attrs:{"addr-name":_vm.orderInfo.consigneeName,"addr-mobile":_vm.orderInfo.consigneePhone,"receiving-address":_vm.orderInfo.receivingAddress}}):_vm._e(),(_vm.orderInfo.deliveryWay !== 'EXPRESS')?_c('per-contact',{attrs:{"people-info":_vm.peopleInfo}}):_vm._e(),(_vm.orderInfo.seatList)?_c('seat-info',{attrs:{"is-border":false,"info":_vm.orderInfo.seatList}}):_vm._e(),_c('pay-line'),(_vm.orderInfo.payInfoList)?_c('order-details',{attrs:{"info":_vm.orderInfo.payInfoList,"is-mall":false,"is-order":true,"show-time":_vm.orderInfo.showTime,"order-status":_vm.orderInfo.orderStatus,"order-freight-amt":_vm.orderInfo.orderFreightAmt,"policy-disc-amt":_vm.orderInfo.policyDiscAmt,"coupon-disc-amt":_vm.orderInfo.couponDiscAmt,"gift-card-amt":_vm.orderInfo.giftCardAmt,"integral-subsidy":_vm.orderInfo.integralSubsidy,"all-price":_vm.orderInfo.actuallyPaidAmt}}):_vm._e(),_c('order-info',{attrs:{"order-code":_vm.orderInfo.salesOrderCode,"order-time":Number(_vm.orderInfo.orderCreateTime),"pay-time":_vm.orderInfo.payTime || 0,"pay-way":_vm.orderInfo.payWayCode,"order-status":_vm.orderInfo.orderStatus,"product-source-enum":_vm.productSourceEnum}}),(
      _vm.orderInfo.orderStatus !== 'PEND_PAYMENT' &&
      _vm.orderInfo.orderStatus !== 'CANCLE' &&
      _vm.productSourceEnum === 'POLY'
    )?_c('InvoiceInfo',{attrs:{"invoice-status":_vm.orderInfo.invoiceStatus,"invoice-explain":_vm.orderInfo.invoiceExplain,"show-time":_vm.orderInfo.showTime,"product-owner-group":_vm.orderInfo.productOwnerGroup,"is-mall":false,"actually-paid-amt":_vm.orderInfo.actuallyPaidAmt}}):_vm._e(),(_vm.orderInfo.returnGoodList && _vm.orderInfo.returnGoodList.length > 0)?_c('per-steps',{attrs:{"steps":_vm.orderInfo.returnGoodList.length,"set-data":_vm.orderInfo.returnGoodList}}):_vm._e(),(_vm.orderInfo.contactNumber)?_c('per-telephone',{attrs:{"contact-number":_vm.orderInfo.contactNumber}}):_vm._e(),(
      _vm.orderInfo.orderStatus === 'PEND_PAYMENT' ||
      _vm.orderInfo.orderStatus === 'WAIT_GET_TICKET' ||
      _vm.orderInfo.evaluation === '1'
    )?_c('PayBtn',{attrs:{"order-status":_vm.orderInfo.orderStatus,"evaluation":_vm.orderInfo.evaluation,"is-douyin":_vm.orderInfo.channel === 'douyin_miniapp'},on:{"payCancel":_vm.payCancel,"payConfirm":_vm.payConfirm,"comment":_vm.comment}}):_vm._e(),_c('Survery',{attrs:{"order-info":_vm.orderInfo}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }