





































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  NavBar,
  Image,
  Icon,
  Row,
  Swipe,
  SwipeItem,
  Stepper,
  Tab,
  Tabs,
  Toast,
} from "vant";
import CommoditySeen from "./components/CommoditySeen.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Stepper.name]: Stepper,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    CommoditySeen,
  },
})
export default class CommodityExchange extends Mixins(Mixin) {
  dataInfo = {
    show: false,
    order: {
      commodityInfoId: this.$route.query.commodityId,
      num: 1,
      specsRelationId: "",
      remainStock: 0,
    },
  };
  pur = 1; //最大数量
  stepperNum = 1; //默认数量
  winningId = ""; //中奖id
  current = 0;
  scrollTop = 0;
  active = 1;
  mallInfo: good.CommodityDto = {};
  commodityId = "";
  get memberLevelId(): string {
    return this.UserModule.memberLevelId || "0";
  }

  mounted(): void {
    let data = JSON.parse(String(this.$route.query.data));
    this.pur = data.num;
    this.commodityId = data.commodityInfoId;
    this.winningId = data.winningId || "";
    this.dataInfo.order.num = data.num;
    this.getInfo();
    window.addEventListener("scroll", this.handleScroll);
  }

  beforeDestroy(): void {
    window.removeEventListener("scroll", this.handleScroll);
  }

  dealPicture(desc: string): string {
    return desc
      ? desc.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
      : "";
  }

  timeFilter(show: boolean, time?: string): string {
    if (!time) {
      return "00:00:00";
    }
    let date = new Date(time);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let month: number | string = "";
    let days: number | string = "";
    let hours: number | string = "";
    month = m < 10 ? "0" + m : m;
    let d = date.getDate();
    days = d < 10 ? "0" + d : d;
    let h = date.getHours();
    hours = h < 10 ? "0" + h : h;
    let minute = date.getMinutes();
    // let second = date.getSeconds();
    let min = minute < 10 ? "0" + minute : minute;
    // s = second < 10 ? "0" + second : second;
    if (show == false) {
      return y + "." + month + "." + days;
    } else {
      return y + "." + month + "." + days + " " + hours + ":" + min;
    }
  }

  /*
   *弹窗规格
   */

  showSpecification(): void {
    if (this.mallInfo.status === "01" || this.mallInfo.status === "05") {
      this.dataInfo.show = true;
    }
  }

  /*
   *按钮名称
   */
  btnName(value?: string): string {
    let name = "";
    if (value == "01") {
      name = "立即兑换";
    } else if (value == "02") {
      name = "已下架";
    } else if (value == "03") {
      name = "即将开售";
    } else if (value == "04") {
      name = "已结束";
    } else if (value == "05") {
      name = "立即兑换";
    } else {
      name = "立即兑换";
    }
    return name;
  }
  handleScroll(): void {
    this.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
  }
  onClickLeft(): void {
    this.$router.go(-1);
  }
  onChange(index: number): void {
    this.current = index;
  }
  /*
   * 获取详情信息
   */
  getInfo(): void {
    this.$api.goodApi.commodity.getCommodityDetail(
      Number(this.commodityId),
      ({ data }) => {
        let obj: good.CommodityDto = data;
        obj.lowestMemberPrices = obj.lowestMemberPrices?.filter((item) => {
          if (item.memberGradeId == this.memberLevelId) {
            return item.memberGradeId == this.memberLevelId;
          } else {
            return item.memberGradeId == "0";
          }
        });
        // this.pur = obj.limitNum ? obj.limitNum : 0;
        this.mallInfo = obj;
      }
    );
  }
  /*
   * 去图片详情页
   */
  goImgDetails(): void {
    let image = JSON.stringify(this.mallInfo.commoditiesPicture);
    this.$router.push(`/mall/details-img?url=${image}`);
  }
  /*
   * 立即购买
   */
  goOrder(): void {
    if (this.mallInfo.status == "01") {
      if (this.UserModule.isLogin) {
        //有规格选择时
        if (
          this.mallInfo.commoditySpecs &&
          (this.mallInfo.commoditySpecs.length > 1 ||
            (this.mallInfo.commoditySpecs[0].commoditySpecsDetails &&
              this.mallInfo.commoditySpecs[0].commoditySpecsDetails.length > 1))
        ) {
          this.dataInfo.show = true;
          return;
        }
        this.getSpecs();
      } else {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      }
    } else if (this.mallInfo.status == "05") {
      return;
      //缺货登记
      // if (this.UserModule.isLogin) {
      //   if (this.mallInfo.commoditySpecs!.length > 1) {
      //     this.dataInfo.show = true;
      //     return;
      //   } else {
      //     if (this.mallInfo.outStockRegister) {
      //       this.OutStock();
      //     } else {
      //       return;
      //     }
      //   }
      // } else {
      //   this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
      //     router: this.$router,
      //     newPage: true,
      //     goBackAfterLogin: true,
      //   });
      // }
    } else {
      return;
    }
  }
  /*
   * 提交信息
   */
  getSpecs(): void {
    this.$api.orderApi.goodOrder.createIntegralmallOrderNew(
      {
        commodityInfoId: Number(this.commodityId),
        num: this.stepperNum,
        specsRelationId: this.mallInfo.commoditySpecs
          ? this.mallInfo.commoditySpecs[0].specsRelationId
          : "",
        winningId: this.winningId,
      },
      ({ data }) => {
        let channel = this.ChannelModule.channel;
        this.getOrderSheet(channel, data, this.mallInfo.fictitious);
      },
      (error) => {
        Toast(error);
      }
    );
  }
  /*
   * 缺货登记
   */
  OutStock(): void {
    let commodityInfoId = this.$route.query.commodityId;
    let specsRelationId = this.mallInfo.commoditySpecs
      ? this.mallInfo.commoditySpecs[0].specsRelationId
      : "";
    this.$api.memberApi.record.userLackCommodityRecord(
      Number(commodityInfoId),
      specsRelationId!,
      () => {
        this.$toast("提交成功");
      },
      (error) => {
        error && this.$toast(error);
      }
    );
  }
}
